<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      width="1300px"
      :ok-button-props="{ props: { loading: submitting } }"
      title="选择套餐"
    >
      <template slot="footer">
        <a-button @click="handleCancel">
          取消
        </a-button>
        <a-button type="primary" @click="handleNext" v-show="isShowPackageList">
          下一步
        </a-button>
        <a-button
          type="primary"
          @click="handleBack"
          v-show="isShowItems || isShowNotificationFamily"
        >
          上一步
        </a-button>
        <a-button type="primary" v-show="isShowItems || isShowNotificationFamily" @click="handleSubmit">
          确定
        </a-button>
      </template>
      <a-form v-show="isShowPackageList" class="custom-nested-search-form" :form="searchPackageForm" @submit="handlePackageSearch">
        <a-row :gutter="24">
          <a-col :span="12" :xxl="4">
            <a-form-item>
              <a-input
                v-decorator="['package_key', {
                  normalize: this.$lodash.trim
                }]"
                placeholder="请输入套餐名称或项目代码或拼音码"
                allow-clear
                autocomplete="off"
              />
            </a-form-item>
          </a-col>
          <a-col :span="4" :xxl="4">
            <a-form-item>
              <a-space>
                <a-button type="primary" @click="handlePackageSearch">
                  搜索
                </a-button>
                <a-button @click="handlePackageReset">
                  刷新
                </a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-form
        class="custom-compact-form"
        :form="packageForm"
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 200 }"
      >
        <a-form-item>
          <a-radio-group
            class="package-selected-box"
            v-show="isShowPackageList"
            v-decorator="['package_id']"
            @change="onChange"
          >
            <div class="custom-flex" style="flex-wrap:wrap">
              <div
                v-for="(item,index) in packageList"
                :key="item.id"
                style="margin-left: 30px;width: 210px"
                @mouseenter="enter(index)"
                @mouseleave="leave()"
              >
                <div class="set-radio">
                  <a-radio :value="item.id" />
                </div>
                <div
                  :class="(seen&&index==current)|| value == item.id? 'set-img-box-focus':'set-img-box'"
                  :style="{backgroundImage:'url(' + imageUrl(item) + ')',
                           backgroundRepeat:'no-repeat'}"
                  v-if="imageUrl(item) != ''"
                  @click="() => {
                    packageForm.setFieldsValue({
                      package_id: item.id
                    })
                    value = item.id
                  }"
                />
                <div
                  :class="(seen&&index==current)|| value == item.id? 'set-img-box-focus':'set-img-box'"
                  style="backgroundImage:url('https://res.anxiaotang.com/assets/6e7f45f0-858f-4a9e-8430-a1ac92ef8cda');backgroundRepeat:'no-repeat'"
                  v-else
                  @click="() => {
                    packageForm.setFieldsValue({
                      package_id: item.id
                    })
                    value = item.id
                  }"
                />
                <div class="padding-top-20">
                  {{ item.name }}
                  <div style="color: #52d273">基础价格：￥ {{ item.price | formatCurrency }}</div>
                </div>
                <div
                  :title="item.remark"
                  class="set-remark-box"
                >
                  {{ item.remark }}
                </div>
              </div>
            </div>
          </a-radio-group>
        </a-form-item>
      </a-form>

      <div v-show="isShowItems">
        <div class="custom-flex set-background-box">
          <div style="-webkit-flex: 1;flex: 1;">
            <div v-if="packageInfo.self_choose === false" class="package-name-count">
              <div style="font-weight: bolder">{{ packageInfo.package_name }}</div>
              共{{ packageInfo.item_count }}项
            </div>
            <div v-else>
              <a-form class="custom-nested-search-form" :form="searchForm" @submit="handleSearch">
                <a-row :gutter="24">
                  <a-col :span="8" :xxl="4">
                    <a-form-item>
                      <a-input
                        v-decorator="['item_name', {
                          normalize: this.$lodash.trim
                        }]"
                        placeholder="请输入项目名称或项目代码或拼音码"
                        allow-clear
                        autocomplete="off"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="4" :xxl="4">
                    <a-form-item label="类型">
                      <a-select
                        allow-clear
                        @focus="fetchItemTypes"
                        placeholder="请选择类型"
                        v-decorator="['item_type']"
                        @change="handleChangeType"
                      >
                        <a-select-option
                          v-for="itemType in itemTypes"
                          :key="itemType.slug"
                          :value="itemType.slug"
                        >
                          {{ itemType.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="4" :xxl="4">
                    <a-form-item>
                      <a-space>
                        <a-button type="primary" @click="handleSearch">
                          搜索
                        </a-button>
                        <a-button @click="handleReset">
                          刷新
                        </a-button>
                      </a-space>
                    </a-form-item>
                  </a-col>
                  <a-col :span="4" :xxl="4">
                    <a-form-item>
                      <a-checkbox @change="displayChoose">显示已选</a-checkbox>
                    </a-form-item>
                  </a-col>
                  <a-col :span="4" :xxl="4">
                    <a-form-item>
                      已选{{ selectedRowKeys.length }}项
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </div>
          </div>

          <div class="custom-flex" style="background-color: #ff9e1c">
            <a-icon type="pay-circle" style="height: 35px;width: 35px;color: #ffffff;margin: 3px;fontSize:45px" />
            <div style="color:#ffffff;padding-left: 10px;">
              套餐金额：
              <div class="set-price-font">{{ packageInfo.package_price | formatCurrency }}元</div>
            </div>
          </div>
        </div>
        <a-table
          v-if="packageInfo.self_choose === true"
          :columns="columns"
          :data-source="itemList"
          :loading="loading"
          :pagination="false"
          row-key="id"
          :scroll="{ y: 350 }"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        >
          <template slot="images" slot-scope="imgs, record">
            <span
              v-if="imgs && imgs.length > 0"
              class="custom-blue"
              @click="showImg(record)"
            >{{ imgs[0].response.filename }}</span>
          </template>
        </a-table>
        <a-table
          v-else
          :columns="columns"
          :data-source="itemList"
          :loading="loading"
          :pagination="false"
          row-key="id"
          :scroll="{ y: 350 }"
        >
          <template slot="images" slot-scope="imgs, record">
            <span
              v-if="imgs && imgs.length > 0"
              class="custom-blue"
              @click="showImg(record)"
            >{{ imgs[0].response.filename }}</span>
          </template>
        </a-table>
        <div style="margin-top:10px">
          <a-form class="custom-nested-search-form" :form="itemForm">
            <a-row :gutter="100">
              <a-col :span="5" :xxl="6">
                <a-form-item>
                  <a-checkbox @change="makeVideoOrPortrait" :checked="isMakeVideoOrPortrait" v-decorator="['make_video']">
                    制作视频/遗像
                  </a-checkbox>
                </a-form-item>
              </a-col>

              <a-col :span="8" :xxl="6" v-if="isMakeVideoOrPortrait">
                <a-form-item label="负责人">
                  <a-select
                    v-decorator="['user_id', {
                      initialValue: defaultUser.id > 0 ? defaultUser.id : undefined,
                      rules: [
                        {
                          required: true,
                          message: '请选择负责人'
                        }
                      ]
                    }]"
                    placeholder="请选择负责人"
                    @load="loadingUserOptions"
                    @change="handlePhoneNumberChange"
                    allow-clear
                  >
                    <a-select-option
                      v-for="user in userOptions"
                      :key="user.id"
                      :value="user.id"
                    >
                      {{ user.emp_name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="5" :xxl="6" v-if="isMakeVideoOrPortrait">
                <a-form-item label="联系电话">
                  {{ phoneNumber }}
                </a-form-item>
              </a-col>
            </a-row>

            <a-form-item label="套餐备注">
              <a-textarea
                v-decorator="['remark', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { max: 1000, message: '请输入备注，最多1000个字符' },
                  ]
                }]"
              />
            </a-form-item>
          </a-form>

        </div>
      </div>

      <div v-show="isShowNotificationFamily">
        <a-form
          class="custom-compact-form"
          :form="notificationForm"
          :label-col="{ span: 2 }"
          :wrapper-col="{ span: 200 }"
        >

          <a-tabs v-model="activeKey">
            <a-tab-pane key="packageDetail" tab="套餐详情">
              <div class="custom-flex custom-justify-between">
                <div>
                  套餐名称：{{ packageInfo.package_name }}
                </div>
                <div>
                  共{{ packageInfo.item_count }}项
                  套餐金额 {{ packageInfo.package_price | formatCurrency }}元
                </div>
              </div>
              <a-table
                :columns="itemsColumns"
                :data-source="chooseList"
                :loading="loading"
                :pagination="false"
                row-key="id"
                :scroll="{ y: 350 }"
              />
            </a-tab-pane>
            <a-tab-pane key="extraContent" tab="套餐以外收费项目" force-render>
              <a-form-item>
                <wang-editor-frequently-asked-question
                  v-decorator="['extra_content', {
                    rules: [
                      { max: 3000, message: '最多3000个字符' },
                    ]
                  }]"
                />
              </a-form-item>
            </a-tab-pane>
            <a-tab-pane key="buddhistNotes" tab="佛事注意事项" force-render>
              <a-form-item>
                <wang-editor-frequently-asked-question
                  v-decorator="['buddhist_notes', {
                    rules: [
                      { max: 3000, message: '最多3000个字符' },
                    ]
                  }]"
                />
              </a-form-item>
            </a-tab-pane>
          </a-tabs>

        </a-form>
      </div>

      <!-- 预览图片 -->
      <preview-image
        :visible.sync="previewVisible"
        :images.sync="previewImages"
      />

    </a-modal>
  </div>
</template>

<script>

import PreviewImage from '@/components/PreviewImage'
import WangEditorFrequentlyAskedQuestion from '@/components/Editor/WangEditor'
import { findPackages } from '@/api/package_management'
import { findTenantTaskUserOptions, findUser } from '@/api/user'
import {
  findSelectOrderPackages,
  choosePackage
} from '@/api/order'
import { formatCurrency } from '@/utils/filter'
import { checkItemValid, getItemTypeOptions } from '@/api/item'

export default {
  name: 'SelectPackage',
  components: {
    PreviewImage,
    WangEditorFrequentlyAskedQuestion
  },
  data() {
    return {
      packageForm: this.$form.createForm(this, { name: 'package_select' }),
      itemForm: this.$form.createForm(this, { name: 'package_item_select' }),
      notificationForm: this.$form.createForm(this, { name: 'notification_family_members' }),
      searchForm: this.$form.createForm(this, { name: 'search_form' }),
      searchPackageForm: this.$form.createForm(this, { name: 'search_package_form' }),
      phoneNumber: '',
      seen: false,
      current: 0,
      value: 0,
      submitting: false,
      loading: false,
      isShowPackageList: true, // 是否展示套餐列表页
      isShowItems: false, // 是否展示项目列表页
      previewVisible: false,
      isShowNotificationFamily: false,
      isChangePackage: false, // 是否变更了套餐
      isMakeVideoOrPortrait: true, // 是否制作视频
      previewImages: [],
      activeKey: 'packageDetail',
      packageList: [],
      defaultPackageList: [],
      packageInfo: {},
      userOptions: [],
      defaultUser: {},
      loadingUserOptions: false,
      itemName: '',
      itemType: 0,
      selectedRowKeys: [],
      oldSelectedRowKeys: [],
      defaultItemList: [],
      itemList: [],
      itemTypes: [],
      chooseList: [] // 已选套餐
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderPackageId: {
      type: Number,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    funeralHomeId: {
      type: Number,
      required: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    columns() {
      return [
        {
          title: '项目名称',
          dataIndex: 'name',
          width: 80
        },
        {
          title: '项目代码',
          dataIndex: 'code',
          width: 80
        },
        {
          title: '拼音码',
          dataIndex: 'pinyin',
          width: 80
        },
        {
          title: '类型',
          dataIndex: 'item_type',
          width: 80
        },
        {
          title: '计价单位',
          dataIndex: 'unit',
          width: 100
        },
        {
          title: '计价个数',
          dataIndex: 'count',
          width: 100
        },
        {
          title: '价格（元）',
          dataIndex: 'price',
          width: 100,
          customRender: formatCurrency
        },
        {
          title: '项目介绍',
          width: 220,
          dataIndex: 'remark',
          ellipsis: true
        }
      ]
    },
    itemsColumns() {
      return [
        {
          title: '项目名称',
          dataIndex: 'name',
          width: 80
        },
        {
          title: '类型',
          dataIndex: 'item_type',
          width: 80
        },
        {
          title: '计价单位',
          dataIndex: 'unit',
          width: 100
        },
        {
          title: '计价个数',
          dataIndex: 'count',
          width: 100
        },
        {
          title: '价格（元）',
          dataIndex: 'price',
          width: 100,
          customRender: formatCurrency
        },
        {
          title: '项目介绍',
          width: 220,
          dataIndex: 'remark',
          ellipsis: true
        }
      ]
    }
  },
  created() {
    // eslint-disable-next-line no-unused-expressions
    this.fetchData()
    this.initPackage()
    this.fetchUserOptions()
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  methods: {
    displayChoose(e) {
      if (e.target.checked) {
        const tempList = []
        this.defaultItemList.forEach((option) => {
          if (this.selectedRowKeys.includes(option.id)) {
            tempList.push(option)
          }
        })
        this.itemList = tempList
      } else {
        this.itemList = this.defaultItemList
      }
    },
    handleChangeType(e) {
    },
    fetchItemTypes() {
      getItemTypeOptions().then((res) => {
        if (res.code === 0) {
          this.itemTypes = res.data
        }
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      let totalFee = 0.0
      const tempList = []
      this.defaultItemList.forEach((option) => {
        if (this.selectedRowKeys.includes(option.id)) {
          totalFee += option.price
          tempList.push(option)
        }
      })
      this.packageInfo.package_price = totalFee
      this.packageInfo.item_count = this.selectedRowKeys.length
      this.chooseList = tempList
    },
    handleReset(e) {
      this.searchForm.setFieldsValue({
        item_type: '',
        item_name: ''
      })
      this.itemList = this.defaultItemList
    },
    handlePackageReset(e) {
      this.searchPackageForm.setFieldsValue({
        package_key: ''
      })
      this.packageList = this.defaultPackageList
    },
    handleSearch(e) {
      e.preventDefault()
      const itemName = this.searchForm.getFieldValue('item_name')
      const itemTypeSlug = this.searchForm.getFieldValue('item_type')
      const tempList = []
      if (itemName === undefined && itemTypeSlug === undefined) {
        this.itemList = this.defaultItemList
      } else {
        this.defaultItemList.forEach((option) => {
          if ((itemName != undefined && (option.name.indexOf(itemName) > -1 || option.code.indexOf(itemName) > -1 || option.pinyin.indexOf(itemName) > -1) || itemName === undefined) &&
              (itemTypeSlug != undefined && option.item_type_slug === itemTypeSlug || itemTypeSlug === undefined)) {
            tempList.push(option)
          }
        })
        this.itemList = tempList
      }
    },
    handlePackageSearch(e) {
      e.preventDefault()
      const packageKey = this.searchPackageForm.getFieldValue('package_key')
      const tempList = []
      if (packageKey === undefined) {
        this.packageList = this.defaultPackageList
      } else {
        this.defaultPackageList.forEach((option) => {
          if (packageKey != undefined && (option.name.indexOf(packageKey) > -1 || option.code.indexOf(packageKey) > -1 || option.pinyin.indexOf(packageKey) > -1) || packageKey === undefined) {
            tempList.push(option)
          }
        })
        this.packageList = tempList
      }
    },
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },
    imageUrl(item) {
      if (item.images) {
        return item.images[0].url
      } else {
        return ''
      }
    },
    fetchData() {
      findPackages({ effective: 'true', funeral_home_id: this.funeralHomeId }).then((res) => {
        if (res.code === 0) {
          this.packageList = res.data
          this.defaultPackageList = res.data
        }
      })
    },
    handlePhoneNumberChange(e) {
      if (e) {
        findUser(e).then((res) => {
          if (res.code === 0) {
            this.phoneNumber = res.data.phone_number
          }
        })
      }
    },
    fetchUserOptions() {
      this.loadingUserOptions = true
      findTenantTaskUserOptions({ task_type: 'make_dead_or_video', order_id: this.serviceOrderId, order_type: 'service' }).then((res) => {
        if (res.code === 0) {
          this.userOptions = res.data.option_list
          this.defaultUser = res.data.default_user
          this.phoneNumber = this.defaultUser.phone_number
        }
        this.loadingUserOptions = false
      })
    },
    // 选择套餐页事件
    initPackage() {
      // TODO 发起请求获取套餐列表
      this.$nextTick(() => {
        this.packageForm.setFieldsValue({ package_id: this.serviceOrderPackageId })
        this.value = this.serviceOrderPackageId
      })
    },
    onChange(e) {
      this.value = e.target.value
    },

    enter(index) {
      this.seen = true
      this.current = index
    },

    leave() {
      this.seen = false
      this.current = null
    },

    makeVideoOrPortrait(e) {
      this.isMakeVideoOrPortrait = e.target.checked
    },

    selectUser(e) {
      this.UserOptions.map(user => {
        if (user.id === e) {
          this.phoneNumber = user.phone_number
        }
      })
    },

    // 家属告知书页事件
    // 初始化家属告知书
    initNotificationFamily() {
      this.notificationForm.setFieldsValue({
        extra_content: this.packageInfo.extra_content,
        buddhist_notes: this.packageInfo.buddhist_notes
      })
    },

    handleCancel() {
      this.isShow = false
    },

    handleNext() {
      if (this.value === 0) {
        this.$warning({
          title: '请选择套餐',
          content: ''
        })
      } else {
        findSelectOrderPackages(this.value, { service_order_id: this.serviceOrderId }).then(res => {
          if (res.code === 0) {
            this.packageInfo = res.data
            this.itemList = this.packageInfo.item_list
            this.defaultItemList = this.packageInfo.item_list
            if (this.packageInfo.self_choose === false) {
              this.chooseList = this.itemList
            } else {
              this.chooseList = res.data.chosen_items
            }
            this.selectedRowKeys = []
            this.oldSelectedRowKeys = []
            res.data.chosen_items.forEach((option) => {
              this.selectedRowKeys.push(option.id)
              this.oldSelectedRowKeys.push(option.id)
            })
            this.packageInfo.item_count = this.selectedRowKeys.length
            if (this.packageInfo.package_deleted === true) {
              this.$warning({
                title: '原套餐已删除，请重新选择套餐',
                content: ''
              })
              return
            }
            this.isShowPackageList = false
            this.isShowItems = true
            if (this.value !== this.serviceOrderPackageId) {
              this.isChangePackage = true
            }
            this.packageForm.validateFields((err, values) => {
              if (!err) {
                if (this.isChangePackage) {
                  // 关闭套餐列表，打开项目列表
                  this.isShowPackageList = false
                  this.isShowItems = true
                }
              }
            })
          }
        })
      }
    },
    handleBack() {
      if (this.isShowItems) {
        this.isShowItems = false
        this.isShowPackageList = true
      }
      if (this.isShowNotificationFamily) {
        this.isShowNotificationFamily = false
        this.isShowItems = true
      }
    },
    choosePackageCommit(editData) {
      choosePackage(this.serviceOrderId, editData).then((res) => {
        if (res.code === 0) {
          // 关闭模态框
          this.isShow = false
          // 告知父组件已完成
          this.$emit('completed')
        }
      })
    },
    selfChoseItemChange() {
      if (this.packageInfo.self_choose === false) {
        return false
      } else {
        if (this.oldSelectedRowKeys.length === this.selectedRowKeys.length) {
          this.oldSelectedRowKeys.forEach((id, index) => {
            if (!this.selectedRowKeys.includes(id)) {
              return false
            }
          })
        } else {
          return true
        }
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.isShowItems) {
        if (!this.isChangePackage && !this.selfChoseItemChange()) {
          this.itemForm.validateFields((err, values) => {
            if (!err) {
              this.submitting = true
              const editData = Object.assign({}, values, { make_video: this.isMakeVideoOrPortrait, package_id: this.value, 'item_ids[]': this.selectedRowKeys })
              checkItemValid(Object.assign({}, { item_ids: this.selectedRowKeys.join(',') })).then((res) => {
                if (res.code === 0) {
                  this.choosePackageCommit(editData)
                }
              })
              this.submitting = false
            }
          })
        } else {
          this.itemForm.validateFields((err, values) => {
            if (!err) {
              this.isShowItems = false
              this.isShowNotificationFamily = true
              this.initNotificationFamily()
            }
          })
        }
      } else {
        this.submitting = true
        this.itemForm.validateFields((err, values) => {
          if (!err) {
            const editData = Object.assign(this.notificationForm.getFieldsValue(), values, {
              make_video: this.isMakeVideoOrPortrait,
              package_id: this.value,
              'item_ids[]': this.selectedRowKeys
            })
            checkItemValid(Object.assign({}, { item_ids: this.selectedRowKeys.join(',') })).then((res) => {
              if (res.code === 0) {
                this.choosePackageCommit(editData)
              }
            })
          }
          this.submitting = false
        })
      }
    }
  }

}
</script>

<style scoped lang="less">
.set-img-box {
  background-size: 100% 100%;
  margin: 5px;
  display: inline-block;
  width: 200px;
  height: 200px;
  position: relative;
}

.set-img-box-focus {
  position: relative;
  background-size: 100% 100%;
  margin: 5px;
  display: inline-block;
  width: 200px;
  height: 200px;
  box-shadow: -1px -1px 10px 5px #a1a1a1;
}

.set-radio {
  position: relative;
  z-index: 1;
}

.set-remark-box {
  width: 210px;
  padding-top: 20px;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.padding-top-20 {
  padding-top: 20px;
}

.set-background-box {
  margin: 10px;
  box-shadow: -1px -1px 10px 5px #EEEFEFFF;
  padding: 10px;

  .package-name-count {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.set-price-font {
  font-size: 10px;
  font-size: 23px;
}

</style>
